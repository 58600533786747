.bf-t-transform-uppercase {
  text-transform: uppercase !important;
}

.bf-t-transform-none {
  text-transform: none !important;
}

.bf-t-left {
  text-align: left !important;
}

.bf-t-center {
  text-align: center !important;
}

.bf-t-right {
  text-align: right !important;
}

.bf-t-shadow {
  text-shadow: 0 0 2px $bf-dark !important;
  text-shadow: 0 0 .2rem $bf-dark !important;
}

.bf-t-italic {
  font-style: italic !important;
}

// useful to 'reset' <i> inherited style
.bf-t-style-normal {
  font-style: normal !important;
}

// useful to 'reset' <b> inherited style
.bf-t-weight-300 {
  font-weight: 300 !important;
}

.bf-t-weight-400 {
  font-weight: 400 !important;
}

.bf-t-weight-500 {
  font-weight: 500 !important;
}

.bf-t-weight-600 {
  font-weight: 600 !important;
}

.bf-t-weight-700 {
  font-weight: 700 !important;
}

.bf-t-weight-800 {
  font-weight: 800 !important;
}

.bf-text-break {
  word-wrap: break-word !important;
}

.bf-t-truncate {
  max-width: 100% !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
  word-wrap: normal !important;
}

.bf-t-truncate--multiline-2 {
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  display: -webkit-box !important;
  -webkit-line-clamp: 2 !important;
  -webkit-box-orient: vertical !important;
}

.bf-t-truncate--multiline-3 {
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  display: -webkit-box !important;
  -webkit-line-clamp: 3 !important;
  -webkit-box-orient: vertical !important;
}


.bf-no-select {
  user-select: none !important;

  // this is a not standard CSS property, so it's for webkit only
  // https://developer.mozilla.org/en-US/docs/Web/CSS/-webkit-touch-callout
  // useful on iOS
  // https://css-tricks.com/force-selection-text-block/#article-header-id-7
  // https://ishadeed.com/article/selection-in-css scroll down to
  // 'selection on mobile' section
  -webkit-touch-callout: none !important;
}

.bf-font-sans-serif {
  font-family:
    /* Safari for OS X and iOS (San Francisco) */ -apple-system,
    /* Chrome < 56 for OS X (San Francisco) */ BlinkMacSystemFont,
    /* Windows */ 'Segoe UI', /* Android */ Roboto,
    /* Linux with KDE */ Oxygen-Sans, /* Ubuntu (all variants) */ Ubuntu,
    /* Linux with GNOME Shell */ Cantarell,
    /* macOS 1.10 and below */ 'Helvetica Neue', Helvetica,
    /* Basic web fallback */ Arial, sans-serif,
    /* emoji fonts */ 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
    'Noto Color Emoji' !important;
}

.bf-font-serif {
  font-family: Georgia, Times, 'Times New Roman', serif,
    /* emoji fonts */ 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
    'Noto Color Emoji' !important;
}

.bf-font-monospace {
  font-family:
    /* macOS 1.10+ */ 'Menlo', /* Windows 6+ */ 'Consolas',
    /* Android 4+ */ 'Roboto Mono', /* Ubuntu 1.10+ */ 'Ubuntu Monospace',
    /* KDE Plasma 5+ */ 'Noto Mono', /* KDE Plasma 4+ */ 'Oxygen Mono',
    /* Linux/OpenOffice fallback */ 'Liberation Mono', /* fallback */ monospace,
    /* emoji fonts */ 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
    'Noto Color Emoji' !important;
}

.bf-h1,
.bf-h2,
.bf-h3,
.bf-h4,
.bf-h5,
.bf-h6 {
  font-weight: 700 !important; // fallback
  font-weight: 600 !important;
  margin-top: 0 !important;
  line-height: 1.2 !important;
  margin-bottom: $bf-spacing-lg-px !important;
  margin-bottom: $bf-spacing-lg-rem !important;
}

.bf-h1 {
  font-size: 36px !important;
  font-size: 3.6rem !important;
}

.bf-h2 {
  font-size: 30px !important;
  font-size: 3rem !important;
}

.bf-h3 {
  font-size: 24px !important;
  font-size: 2.4rem !important;
}

.bf-h4 {
  font-size: 20px !important;
  font-size: 2rem !important;
}

.bf-h5,
.bf-h6 {
  font-size: 16px !important;
  font-size: 1.6rem !important;
}

p.bf-lead {
  font-size: 120% !important;
}

// responsive typography
// https://codepen.io/marcop135/pen/eYmPmJm
// https://css-tricks.com/snippets/css/fluid-typography/
//
// old browsers that don't support CSS variables ignore what follows
// (mostly IE and Opera Mini)
// in this way, old browsers will ignore 'calc' property too, avoiding issues
// https://caniuse.com/#feat=calc
// https://caniuse.com/#feat=css-variables
// https://developer.mozilla.org/en-US/docs/Web/CSS/Using_CSS_custom_properties
body.bf-responsive-typography {
  --body-rt-font-size-flex: calc(1.6rem + 3 * ((100vw - 320px) / 680));
  --body-rt-font-size-max: 1.9rem;
}

@media (min-width: 320px) {
  body.bf-responsive-typography {
    font-size: var(--body-rt-font-size-flex);
  }
}

@media (min-width: 1000px) {
  body.bf-responsive-typography {
    font-size: var(--body-rt-font-size-max);
  }
}
