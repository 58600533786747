// micro-clearfix
// http://nicolasgallagher.com/micro-clearfix-hack/
.bf-clearfix:before,
.bf-clearfix:after {
  content: ' ';
  display: table;
}

.bf-clearfix:after {
  clear: both;
}

.bf-hide {
  display: none !important;
}

// assistive text for screen reader only (e.g. form labels)
.bf-hidden {
  display: none !important;
  visibility: hidden !important;
}

.bf-text-hide {
  /* stylelint-disable-next-line */
  font: 0/0 a !important;
  color: transparent !important;
  text-shadow: none !important;
  background-color: transparent !important;
  border: 0 !important;
  margin: 0 !important;
  padding: 0 !important;
}

/*
// taken from https://github.com/h5bp/html5-boilerplate/blob/v7.3.0/dist/css/main.css
// 
* Hide only visually, but have it available for screen readers:
* https://snook.ca/archives/html_and_css/hiding-content-for-accessibility
*
* 1. For long content, line feeds are not interpreted as spaces and small width
*    causes content to wrap 1 word per line:
*    https://medium.com/@jessebeach/beware-smushed-off-screen-accessible-text-5952a4c2cbfe
*/
.bf-sr-only {
  border: 0 !important;
  clip: rect(0, 0, 0, 0) !important;
  height: 1px !important;
  margin: -1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  white-space: nowrap !important;
  width: 1px !important;
}

/*
// taken from https://github.com/h5bp/html5-boilerplate/blob/v7.3.0/dist/css/main.css
// 
* Extends the .sr-only class to allow the element
* to be focusable when navigated to via the keyboard:
* https://www.drupal.org/node/897638
*/
.bf-sr-only.focusable:active,
.bf-sr-only.focusable:focus {
  clip: auto !important;
  height: auto !important;
  margin: 0 !important;
  overflow: visible !important;
  position: static !important;
  white-space: inherit !important;
  width: auto !important;
}

.bf-invisible {
  visibility: hidden !important;
}

.bf-visible {
  visibility: visible !important;
}

.bf-display-block {
  display: block !important;
}

.bf-display-block-center {
  display: block !important;
  margin-left: auto !important;
  margin-right: auto !important;
  text-align: center !important;
}

.bf-display-inline {
  display: inline !important;
}

.bf-display-inline-block {
  display: inline-block !important;
}

// IE10+
// https://caniuse.com/#feat=flexbox

.bf-display-flex {
  display: flex !important;
}

.bf-display-inline-flex {
  display: inline-flex !important;
}

.bf-display-flex--justify-start {
  justify-content: flex-start !important;
}

.bf-display-flex--justify-end {
  justify-content: flex-end !important;
}

.bf-display-flex--justify-center {
  justify-content: center !important;
}

.bf-display-flex--wrap {
  flex-wrap: wrap !important;
}

.bf-display-flex--nowrap {
  flex-wrap: nowrap !important;
}

.bf-float-left {
  float: left !important;
}

.bf-float-right {
  float: right !important;
}

// ≠ IE
// https://caniuse.com/css-sticky
.bf-position-fixed {
  position: fixed !important;
}

.bf-position-sticky {
  position: sticky !important;
  top: 0 !important;
}

// https://css-tricks.com/centering-css-complete-guide
// Is the element of unknown width and height?
// ! 'transform' is IE10 + https://caniuse.com/#feat=transforms2d
.bf-align-center-unknown {
  position: relative !important;
}

.bf-align-center-unknown--item {
  position: absolute !important;
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%) !important;
}

// align items horizontally and vertically
// https://developer.mozilla.org/en-US/docs/Web/CSS/CSS_Flexible_Box_Layout/Aligning_Items_in_a_Flex_Container
.bf-align-center-flex {
  display: flex;
  align-items: center;
  justify-content: center;
}

.bf-width-25 {
  width: 25% !important;
}

.bf-width-33 {
  width: 33.33333333333333% !important;
}

.bf-width-50 {
  width: 50% !important;
}

.bf-width-75 {
  width: 75% !important;
}

.bf-width-100 {
  width: 100% !important;
}

.bf-width-auto {
  width: auto !important;
}
