.bf-btn {
  display: inline-block;
  cursor: pointer;
  color: $bf-dark;
  padding: calc($bf-spacing-xl-px / 3) (calc($bf-spacing-xl-px / 3) * 2); // 8px 16px
  padding: calc($bf-spacing-xl-rem / 3) (calc($bf-spacing-xl-rem / 3) * 2);
  line-height: normal;
  text-align: center;
  margin: 0;
  background-clip: padding-box;
  vertical-align: baseline;
  overflow: visible;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  user-select: none;

  // ! IE9+
  // https://caniuse.com/#feat=border-radius
  border-radius: $bf-spacing-sm-px;
  border-radius: $bf-spacing-sm-rem;

  border: 0;
  background-image: none; // FF mobile
  background-color: $bf-gray-light;
  transition: all 0.2s;

  &:hover,
  &:active,
  &:focus {
    text-decoration: none;
    color: $bf-dark;
    background: darken($bf-gray-light, 10%);
  }

  &:active,
  &:focus {
    box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.15);
  }

  &:focus {
    outline: 0;
  }

  &.bf-btn--primary {
    color: $bf-white;
    background: $bf-blue;

    &:hover,
    &:active,
    &:focus {
      color: $bf-white;
      background: darken($bf-blue, 10%);
    }

    &:active,
    &:focus {
      box-shadow: 0 0 0 3px rgba(0, 86, 179, 0.25);
    }
  }
}

fieldset[disabled] .bf-btn,
.bf-btn[disabled],
.bf-btn:disabled,
.bf-btn[aria-disabled='true'] {
  cursor: default !important; // fallback
  cursor: not-allowed !important;
  pointer-events: none !important;
  user-select: none !important;
  box-shadow: none !important;
  opacity: 0.5 !important;
}
