// taken from https://getbootstrap.com/docs/4.4/utilities/embed/
// but lightened a bit

.bf-embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;

  &::before {
    display: block;
    content: '';
    padding-top: 56.25%; // 16:9 by default
  }

  .bf-embed-responsive--4-3::before {
    padding-top: 75%;
  }

  & .bf-embed-responsive--item,
  & iframe,
  & embed,
  & object,
  & video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
  }
}
