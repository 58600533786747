// ! ':not' is IE9+
// https://caniuse.com/#feat=mdn-css_selectors_not
//
// ! and ':focus-visible'
// https://developer.mozilla.org/en-US/docs/Web/CSS/:focus-visible
//
// don't have some much browser support now (May 2020)
// https://caniuse.com/#feat=css-focus-visible
//
// but is good in a progressive enhancement future view
//
// and you could polyfill it for a wide cross-browser support right now
// https://github.com/WICG/focus-visible
//
// remove focus if is not keyboard
// and if these elements are not focused globally
:focus:not(:focus-visible) {
  outline: none;
}

// Suppress the focus outline on elements that cannot be accessed via keyboard.
// This prevents an unwanted focus outline from appearing around elements that
// might still respond to pointer events.
//
// Taken from SuitCSS https://github.com/suitcss/base
//
// Copyright 2020 Harry Roberts

// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at

//     http://www.apache.org/licenses/LICENSE-2.0

// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

[tabindex='-1']:focus:not(:focus-visible) {
  outline: 0 !important;
}

// style the ':focus' outline on anchors and inputs
// this is for consistency
// e.g.
// in webkit (Chrome, Safari) the outline is blue with a subtle box-shadow
// in trident (IE) is just pointed black
// in gecko (FF) is pointed black, but it's a thiner
// ...
a:focus,
input:focus {
  outline: 2px solid orange;
}

// style it again (must be separated)
:focus-visible {
  outline: 2px solid orange;
}

// these form elements get some ':focus' styles that enhance and improve the focus
// see '_form-elements.scss'
// so, we can remove it safely
//
// ! but do not touch
// [type="color"]
// [type="range"]
// [type="file"]
// because usually are replaced with a custom verion
// with JS or extra <div>s
// https://getbootstrap.com/docs/4.4/components/input-group/#custom-file-input
button,
[type='button'],
[type='reset'],
[type='submit'],
[type='email'],
[type='number'],
[type='password'],
[type='tel'],
[type='search'],
[type='text'],
[type='date'],
[type='datetime-local'],
[type='month'],
[type='time'],
[type='week'],
[type='url'],
[type='radio'],
[type='checkbox'],
input[list],
select,
textarea {
  &:focus {
    outline: 0;
  }
}

// More resources to read on this matter
// https://gist.github.com/chrisdemars/a74c5bd210dc739fa63e9b985c937bcb
