// this is something similar to Bootstrap's spacing utility
// https://getbootstrap.com/docs/4.4/utilities/spacing/

// margins and paddings classes

.bf-m-0 {
  margin: 0 !important;
}

.bf-m-t-0 {
  margin-top: 0 !important;
}

.bf-m-b-0 {
  margin-bottom: 0 !important;
}

.bf-m-l-0 {
  margin-left: 0 !important;
}

.bf-m-r-0 {
  margin-right: 0 !important;
}

.bf-m-1 {
  margin-bottom: $bf-spacing-md-px !important;
  margin-bottom: $bf-spacing-md-rem !important;
}

.bf-m-2 {
  margin-bottom: $bf-spacing-lg-px !important;
  margin-bottom: $bf-spacing-lg-rem !important;
}

.bf-m-3 {
  margin-bottom: $bf-spacing-xl-px !important;
  margin-bottom: $bf-spacing-xl-rem !important;
}

.bf-m-4 {
  margin-bottom: $bf-spacing-xxl-px !important;
  margin-bottom: $bf-spacing-xxl-rem !important;
}

.bf-m-b-1 {
  margin-bottom: $bf-spacing-md-px !important;
  margin-bottom: $bf-spacing-md-rem !important;
}

.bf-m-b-2 {
  margin-bottom: $bf-spacing-lg-px !important;
  margin-bottom: $bf-spacing-lg-rem !important;
}

.bf-m-b-3 {
  margin-bottom: $bf-spacing-xl-px !important;
  margin-bottom: $bf-spacing-xl-rem !important;
}

.bf-m-b-4 {
  margin-bottom: $bf-spacing-xxl-px !important;
  margin-bottom: $bf-spacing-xxl-rem !important;
}

// paddings

.bf-p-0 {
  padding: 0 !important;
}

.bf-p-t-0 {
  padding-top: 0 !important;
}

.bf-p-b-0 {
  padding-bottom: 0 !important;
}

.bf-p-l-0 {
  padding-left: 0 !important;
}

.bf-p-r-0 {
  padding-right: 0 !important;
}

.bf-p-1 {
  padding-bottom: $bf-spacing-md-px !important;
  padding-bottom: $bf-spacing-md-rem !important;
}

.bf-p-2 {
  padding-bottom: $bf-spacing-lg-px !important;
  padding-bottom: $bf-spacing-lg-rem !important;
}

.bf-p-3 {
  padding-bottom: $bf-spacing-xl-px !important;
  padding-bottom: $bf-spacing-xl-rem !important;
}

.bf-p-4 {
  padding-bottom: $bf-spacing-xxl-px !important;
  padding-bottom: $bf-spacing-xxl-rem !important;
}

.bf-p-t-1 {
  padding-top: $bf-spacing-md-px !important;
  padding-top: $bf-spacing-md-rem !important;
}

.bf-p-t-2 {
  padding-top: $bf-spacing-lg-px !important;
  padding-top: $bf-spacing-lg-rem !important;
}

.bf-p-t-3 {
  padding-top: $bf-spacing-xl-px !important;
  padding-top: $bf-spacing-xl-rem !important;
}

.bf-p-t-4 {
  padding-top: $bf-spacing-xxl-px !important;
  padding-top: $bf-spacing-xxl-rem !important;
}

.bf-p-b-1 {
  padding-bottom: $bf-spacing-md-px !important;
  padding-bottom: $bf-spacing-md-rem !important;
}

.bf-p-b-2 {
  padding-bottom: $bf-spacing-lg-px !important;
  padding-bottom: $bf-spacing-lg-rem !important;
}

.bf-p-b-3 {
  padding-bottom: $bf-spacing-xl-px !important;
  padding-bottom: $bf-spacing-xl-rem !important;
}

.bf-p-b-4 {
  padding-bottom: $bf-spacing-xxl-px !important;
  padding-bottom: $bf-spacing-xxl-rem !important;
}
