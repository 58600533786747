.bf-container,
.bf-container--fluid {
  // avoid IE8 issue with 'box-sizing: border-box' in combination
  // with max/min-width and max/min-height
  // https://github.com/twbs/bootstrap/issues/10014
  //
  box-sizing: content-box;

  // you could add these classes to an inline/inline-block element
  // but it's not recommended
  display: block;

  // centering
  margin-right: auto;
  margin-left: auto;

  // spacing
  //
  // match the default Bootstrap Grid gutter width
  // https://getbootstrap.com/docs/3.4/css/#grid-options
  padding-left: $bf-spacing-grid-gutter-px;
  padding-left: $bf-spacing-grid-gutter-rem;
  padding-right: $bf-spacing-grid-gutter-px;
  padding-right: $bf-spacing-grid-gutter-rem;

  // clearfix
  &:before,
  &:after {
    display: table;
    content: ' ';
  }

  &:after {
    clear: both;
  }
}

.bf-container {
  max-width: 1140px; // 1140 + 15 + 15 = 1170
  max-width: 114rem;
}

.bf-row {
  display: block;

  margin-left: -$bf-spacing-grid-gutter-px;
  margin-left: -$bf-spacing-grid-gutter-rem;
  margin-right: -$bf-spacing-grid-gutter-px;
  margin-right: -$bf-spacing-grid-gutter-rem;

  // clearfix
  &:before,
  &:after {
    content: ' ';
    display: table;
  }

  &:after {
    clear: both;
  }
}

[class^='bf-col-'] {
  // the block display is triggered by 'float: left'
  // display: block;

  float: left;
  position: relative;
  min-height: 1px;

  padding-left: $bf-spacing-grid-gutter-px;
  padding-left: $bf-spacing-grid-gutter-rem;
  padding-right: $bf-spacing-grid-gutter-px;
  padding-right: $bf-spacing-grid-gutter-rem;
}

.bf-no-gutters {
  margin-right: 0;
  margin-left: 0;
}

.bf-no-gutters > [class^='bf-col'] {
  padding-right: 0;
  padding-left: 0;
}

.bf-col-12 {
  width: 100%;
}

.bf-col-11 {
  width: 91.66666666666666%;
}

.bf-col-10 {
  width: 83.33333333333334%;
}

.bf-col-9 {
  width: 75%;
}

.bf-col-8 {
  width: 66.66666666666666%;
}

.bf-col-7 {
  width: 58.333333333333336%;
}

.bf-col-6 {
  width: 50%;
}

.bf-col-5 {
  width: 41.66666666666667%;
}

.bf-col-4 {
  width: 33.33333333333333%;
}

.bf-col-3 {
  width: 25%;
}

.bf-col-2 {
  width: 16.666666666666664%;
}

.bf-col-1 {
  width: 8.3333%;
}

// ! this is IE9+
// if you need CSS3 Media Queries support in IE8
// you could use the respond.js polyfll https://github.com/scottjehl/Respond
//
// ! but there are some important caveats
// https://github.com/scottjehl/Respond#support--caveats

// 'break' the grid and make the columns full-width
@media (max-width: 575px) {
  .bf-container--break-xs .bf-row {
    margin-left: 0;
    margin-right: 0;
  }

  .bf-container--break-xs [class^='bf-col'] {
    width: 100%;
    float: none;
    margin-left: 0;
    padding-left: 0;
    padding-right: 0;
  }
}

@media (max-width: 767px) {
  .bf-container--break-md .bf-row {
    margin-left: 0;
    margin-right: 0;
  }

  .bf-container--break-md [class^='bf-col'] {
    width: 100%;
    float: none;
    margin-left: 0;
    padding-left: 0;
    padding-right: 0;
  }
}

@media (max-width: 991px) {
  .bf-container--break-lg .bf-row {
    margin-left: 0;
    margin-right: 0;
  }

  .bf-container--break-lg [class^='bf-col'] {
    width: 100%;
    float: none;
    margin-left: 0;
    padding-left: 0;
    padding-right: 0;
  }
}

// fix missing table cell borders when using 'bf-col-' classes for column sizing
// https://github.com/twbs/bootstrap/issues/11623
table td[class^='bf-col'],
table th[class^='bf-col'] {
  position: static;
}

// Need more?
//
// IE8+
// Pure CSS grids (ex YUI library) https://purecss.io/grids/
//
// IE8+ (with JS polyfill)
// Bootstrap v3 grid system https://getbootstrap.com/docs/3.4/css/#grid
//
// moder browsers only (partial IE11 support)
// Bulma Flexbox grid system https://bulma.io/documentation/columns/
