.bf-focused {
  transition: border .2s, background-color .2s, box-shadow .2s !important;
  border-color: $bf-blue !important;
  box-shadow: 0 0 0 3px rgba(0, 86, 179, .25) !important;
}

.bf-disabled,
.bf-disabled:hover,
.bf-disabled:active,
.bf-disabled:focus,
.bf-disabled:after,
.bf-disabled:before,
.bf-disabled:invalid,
.bf-disabled:checked {
  cursor: default !important; // IE8-10 fallback
  cursor: not-allowed !important;
  pointer-events: none !important;
  user-select: none !important;
  box-shadow: none !important;
  opacity: .5 !important;

  background-color: $bf-white;
  border-color: $bf-gray;
}

label.bf-disabled {
  background-color: transparent;
  border-color: transparent;
}

button.bf-disabled,
[type='button'].bf-disabled,
[type='reset'].bf-disabled,
[type='submit'].bf-disabled,
.bf-btn.bf-disabled {
  cursor: default;
  cursor: not-allowed;
  pointer-events: none;
  user-select: none;
  box-shadow: none;
  opacity: .5;

  color: $bf-dark;
  background: $bf-gray-light;

  &:hover,
  &:active,
  &:focus {
    box-shadow: none;
    color: $bf-dark;
    background: $bf-gray-light;
  }
}

.bf-btn--primary.bf-disabled {
  cursor: default;
  cursor: not-allowed;
  pointer-events: none;
  user-select: none;
  box-shadow: none;
  opacity: .5;

  color: $bf-white;
  background: $bf-blue;

  &:hover,
  &:active,
  &:focus {
    box-shadow: none;
    color: $bf-white;
    background: $bf-blue;
  }
}

.bf-invalid,
.bf-invalid:focus {
  color: $bf-red !important;
  border-color: $bf-red !important;
  box-shadow: 0 0 0 3px rgba(255, 0, 0, .25) !important;
}
